import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useState } from 'react';
import UserPool from '../UserPool';
import Navbar from "./Navbar";
import {PostApiRequest} from './ApiRequest';
function Register() {
  //const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name,setName] = useState('');
  const [verifyProcess, setVerifyProcess] = useState(false);
  const [OTP, setOTP] = useState('');
  const url =  process.env.REACT_APP_API_BASE_URL + "new/user"; //"https://c8kcdg7bm8.execute-api.ap-south-1.amazonaws.com/dev/api/new/action";
  
  const onSubmit = (e) => {
    e.preventDefault();
    
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email',
        Value: email
      }
      )
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'name',
        Value: name
      }
      )
    );
    UserPool.signUp(email, password, attributeList, null, (err, data) => {
      if (err) {
        console.log(err);
        alert("Couldn't sign up");
      } else {
        console.log(data);
        setVerifyProcess(true);
        alert('User Added Successfully');
      }
    });
  };

  const verifyAccount = (e) => {
    e.preventDefault();
    const payload = {
      Username : name,
      email: email,
      permission:"View",
      updatedby:name,
      updateddate: new Date().toISOString()
    };
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    console.log(user);
    user.confirmRegistration(OTP, true, (err, data) => {
      if (err) {
        console.log(err);
        alert("Couldn't verify account");
      } else {
        console.log(data);
        alert('Account verified successfully');
        window.location.href = '/login';
      }
    });
    PostApiRequest(url,payload);
  };

  
  return(
    <div>
      <Navbar />
      <div className="container-flex-box-wrap  fullHeight">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <img src="/Images/login.svg" className="pad-30-top loginImage" alt="register" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 bgDarkGreen align-items-center flex">
          <div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
          {verifyProcess === false ? (
            <form className="form-group pad-50 txtAlignLeft" onSubmit={onSubmit}>
            <div className="txtWhite fontInter fontWeight700 txtSize38 txtLineHeight53">Register User</div>
            <div className="spacer20 clear"></div>
            <div className="txtWhite fontInter fontWeight400 txtSize20 txtLineHeight28 pad-10-bottom">Name</div>
            <input type="text" autoComplete="off" autoCorrect="off" className="form-control input-text txtDarkGrey" placeholder="Enter name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
            <div className="spacer20 clear"></div>
            <div className="txtWhite fontInter fontWeight400 txtSize20 txtLineHeight28 pad-10-bottom">Email ID</div>
            <input type="text" autoComplete="off" autoCorrect="off" className="form-control input-text txtDarkGrey" placeholder="Enter User name/Email ID" id="EmailID" value={email} onChange={(e) => setEmail(e.target.value)} />
            
            <div className="txtWhite fontInter fontWeight400 txtSize20 txtLineHeight28 pad-20-top pad-10-bottom">Password</div>
            <input type="password" autoComplete="off" autoCorrect="off" className="form-control input-text txtDarkGrey" placeholder="Password$123" id="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <div className="spacer20 clear"></div>
            <div className="">
              <button className="btn btn-primary txtWhite fontInter fontWeight700 txtSize16 letterSpace2 txtLineHeight28" type="submit">
                Register
              </button>
            </div>
          </form>
            ):(
<form className="form-group pad-50 txtAlignLeft" onSubmit={verifyAccount}>
              <div className="txtWhite fontInter fontWeight700 txtSize38 txtLineHeight53">Register User</div>
              <div className="spacer20 clear"></div>
              <div className="txtWhite fontInter fontWeight400 txtSize20 txtLineHeight28 pad-10-bottom">Enter OTP</div>
              <input type="text" autoComplete="off" autoCorrect="off" className="form-control input-text txtDarkGrey" placeholder="Enter OTP" id="otp" value={OTP} onChange={(e) => setOTP(e.target.value)} />
              <div className="spacer20 clear"></div>
              <div className="">
                <button className="btn btn-primary txtWhite fontInter fontWeight700 txtSize16 letterSpace2 txtLineHeight28" type="submit">
                  Verify
                </button>
              </div>
            </form>

              
            )}
          </div>
        </div>
      </div>
    </div>
  );

}

export default Register;
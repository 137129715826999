import React from 'react';
import { Nav } from 'react-bootstrap';
import { Form } from 'react-router-dom';
import Navbar from '../Navbar';
import Status from '../Status';
import Banner from './Banner';
import FormData from './FormData';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import FormContainer from './FormContainer';
import FooterComponent from '../Footer';

function VolunteerPage(props) {
    return (
        <div>
            <Navbar/>
            <Banner />
            {/* <FormData /> */}
            <FormContainer />
            <FooterComponent/>
        </div>
    );
}

export default VolunteerPage;
import { initialState } from "../initialState";
import * as UsersActions from "./usersActions";

export const usersReducer = (state = initialState.users, action) => {
  switch (action.type) {
    case UsersActions.GET_LOGIN_DATA_SUCCESSFULL:
      return {
        ...state,
        userData: action.payload,
      };
    case UsersActions.GET_PERMISSION_DATA_SUCCESSFULL:
      return {
        ...state,
        isPermission: action.payload,
      };
    case UsersActions.IS_DASHBOARD_LOADED:
      return {
        ...state,
        isDashboardLoaded: action.payload ?? initialState.users.isDashboardLoaded,
      };
    default:
      return state;
  }
};

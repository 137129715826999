import React from "react";
 import {  } from "react-bootstrap";

const FooterComponent = () => (
  <>
    <div className="container footerbgcolor">
      <div className="spacer"></div>
      <p>Contact us at: +91 740 9999 111 - 10.00am to 7.00pm IST (Monday to Saturday)</p>
      <p>Copyright © 2024 SankalpTaru.org | All rights reserved</p>
      <div className="spacer"></div>
    </div>
  </>
);

export default FooterComponent;
import React, {useState, useEffect} from 'react'

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)


  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
        window.removeEventListener('resize', changeWidth)
    }

  }, [])

  return (
    <nav className="navbar navbar-expand-lg navbar-light bgDarkGrey">
        <div className="container-fluid">
            <a className="navbar-brand" href="#"><img className="logo" src="/Images/sankalptaru-logo.png"
                     alt="logo"/></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler"
                aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarToggler">
                <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                <li className="nav-item">
                        <a className="nav-link" href="https://sankalptaru.org/about-us/">Who</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://sankalptaru.org/how-it-works/">How</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://sankalptaru.org/our-work/">Where</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://sankalptaru.org/corporate-front-page/">For Corporates</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="http://prc.sankalptaru.org/">R&D Centre</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://sankalptaru.org/#">Knowledge Portal</a>
                    </li>
                    <li className="nav-item">
                        <a className="btn btn-default" href="https://sankalptaru.org/location/">
                            <img src="/Images/tree-icon.svg" alt="Plant Now" className="txtHeight20"/>
                            Plant Now
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="btn btn-brown" href="https://gramyumm.sankalptaru.org/">
                            <img src="/Images/gramyumm.svg" className="txtHeight20" alt="GramYumm"/>
                            GramYumm
                        </a>
                    </li>
                    
                </ul>
            </div>
        </div>
    </nav>
  )
}
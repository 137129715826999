import React, {useEffect, useState} from 'react';
import { Navigate, Outlet  } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default function PrivateRoute() {
    const isAuthorized = useSelector(state => state?.users?.userData?.email_verified || false)

   return (isAuthorized ? <Outlet/> : <Navigate to="/login" replace={true}/>);
   
}
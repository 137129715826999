import React from 'react';
import './FormContainer.css';
import VolunteerForm from './VolunteerForm';
function FormContainer(props) {
    return (
        <div className="container-fluid container-lg enrollment-page">
            <h3 className='enrollment-header'>Sankalptaru Volunteering Form</h3>
            <VolunteerForm />
        </div>
    );
}

export default FormContainer;
import { AuthenticationDetails, CognitoUser, CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUserSession } from "amazon-cognito-identity-js";
import { useState } from "react";
import {Audio} from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import UserPool from "../UserPool";
import Navbar from "./Navbar";
import { PostApiRequest, GetApiRequest } from "./ApiRequest";
import { getLoginDataSuccessfull, getPermissionDataSuccessfull, isDashboardLoaded } from "../redux/users/usersActions";


function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isHide, setIsHide] = useState(false);
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_API_BASE_URL + "new/action";
  const payload = {
    actiontype: "login",
    actiondesc: "Login Attempt",
    actiondoneby: username,
    actiondate: new Date().toISOString(),
  };
  /* const AccessToken = new CognitoAccessToken({
    AccessToken: accessToken
  }); */
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsHide(true);
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        dispatch(getLoginDataSuccessfull(result?.idToken?.payload));
        if (location.pathname === "/login") {
          navigate("/dashboard");
        }
        GetApiRequest(`${process.env.REACT_APP_API_BASE_URL}user/${result?.idToken?.payload?.email}`).then((res) => {
          dispatch(getPermissionDataSuccessfull(res?.data[0]?.permission === "Edit"));
          dispatch(isDashboardLoaded(true));
        });
      },
      onFailure: (err) => {
        console.log("login failure", err);
      },
      //newPasswordRequired: (data) => {
      //  console.log('new password required', data);
      //},
    });
    PostApiRequest(url, payload);
  };

  return (
    <div>
      <Navbar />
      <div className="container-flex-box-wrap  fullHeight">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <img src="/Images/login.svg" className="pad-30-top loginImage" alt="login" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 bgDarkGreen align-items-center flex">
          <div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
            <form className="form-group pad-50 txtAlignLeft" onSubmit={onSubmit}>
              <div className="txtWhite fontInter fontWeight700 txtSize38 txtLineHeight53">Log in</div>
              <div className="spacer20 clear"></div>
              <div className="txtWhite fontInter fontWeight400 txtSize20 txtLineHeight28 pad-10-bottom">Email ID</div>
              <input type="text" autoComplete="off" autoCorrect="off" className="form-control input-text txtDarkGrey" placeholder="Enter Email ID" id="EmailID" value={username} onChange={(e) => setUsername(e.target.value)} />
              <div className="txtWhite fontInter fontWeight400 txtSize20 txtLineHeight28 pad-20-top pad-10-bottom">Password</div>
              <input type="password" autoComplete="off" autoCorrect="off" className="form-control input-text txtDarkGrey" placeholder="Enter Password" id="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              <div className="spacer20 clear"></div>
              <div className="loginBtnSection">
                <button style={isHide ? { padding: "7px 41px 7px 22px", fontSize: '0.8rem'} : {padding: "7px 30px", fontSize: '0.8rem'}} className="btn btn-primary loginbtn txtWhite fontInter fontWeight700 txtSize16 letterSpace2 txtLineHeight28" type="submit">
                  {isHide ? "LOGGING IN" : "LOG IN"}
                </button>
                {isHide && (
                  <div className="loader">
                    <Audio
                      height="25"
                      width="25"
                      color="#4fa94d"
                      ariaLabel="audio-loading"
                      wrapperStyle={{}}
                      wrapperClass="wrapper-class"
                      visible={true}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import React from 'react';

function FormData(props) {
    return (
        <div>
            <div class="form-area">
                <h2>Sankalptaru Volunteering Form </h2>
            </div>
            <div class="container1">
                <form action="#">
                    <div class="form first">
                        <div class="details-personal">
                            <span class="title"></span>
                        </div>
                        <div class="fields">
                            <div class="input-field">
                                <label>Name*</label>
                                <input type="text" placeholder="Enter Name" required />
                            </div>
                            <div class="input-field">
                                <label>Email ID*</label>
                                <input type="email" placeholder="Enter Email ID" required />
                            </div>

                            <div class="input-field">
                                <label>Phone*</label>
                                <input type="tel" placeholder="Enter Phone" required />
                            </div>
                            <div class="input-field pad-30-top">
                                <label>Date of Birth*</label>
                                <input type="text" placeholder="Date/Month/Year" required />
                            </div>
                            <div class="input-field pad-30-top">
                                <label>Select Gender</label>
                                <select class="form-select pad-10-top" aria-label="Default select example">
                                    <option selected>Select Gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                </select>
                            </div>

                            <div class="input-field pad-30-top">
                                <label>Your Blood Group</label>
                                <input type="text" placeholder="Your Blood Group" required />
                            </div>
                            <div class="input-field pad-30-top">
                                <label>Pin Code</label>
                                <input type="text" placeholder="Enter Pin Code" required />
                            </div>
                            <div class="input-field pad-30-top">
                                <label>Address</label>
                                <input class="address-field" type="text" placeholder="Enter Address" required />
                            </div>
                            <div class="input-field pad-30-top"></div>
                            <div class="input-field pad-30-top">
                                <label>Select State</label>
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Select State</option>
                                    <option value="1">Uttar Pradesh</option>
                                    <option value="2">Maharastra</option>
                                    <option value="3">Bihar</option>
                                    <option value="4">West Bangal</option>
                                    <option value="5">Tamil Nadu</option>
                                    <option value="6">Rajasthan</option>
                                    <option value="7">Karnatka</option>
                                    <option value="8">Gujrat</option>
                                    <option value="9">Orissa</option>
                                    <option value="2">Jharkhand</option>
                                </select>
                            </div>
                            <div class="input-field pad-30-top">
                                <label>Select City</label>
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Select City</option>
                                    <option value="1">Muzaffarpur</option>
                                    <option value="2">Allahabad</option>
                                    <option value="3">Patna</option>
                                    <option value="4">Ranchi</option>
                                    <option value="5">Jaipur</option>
                                    <option value="6">Sitamadhi</option>
                                    <option value="7">Banglore</option>
                                    <option value="8">Gandhi nagar</option>
                                    <option value="9">Bhubneswar</option>
                                    <option value="10">Delhi</option>
                                </select>
                            </div>
                            <div class="input-field pad-30-top"></div>

                            <div class="para-area form-group">
                                <div>Have you ever been associated with an Environmental Organisation?
                                    <label class="radio-inline" For="Yes"><input type="radio" id="Yes" name="EnvOrg"
                                            value="Yes" />Yes</label>
                                    <label class="radio-inline" For="No"><input type="radio" id="No" name="EnvOrg"
                                            value="No" checked="checked" />No</label>
                                </div>
                                <div class="mb-3">
                                    <input type="text" class="form-control" id="RadioYesReason"
                                        placeholder="If yes, please specify" />
                                </div>
                            </div>

                            <div class="para-4">
                                <div>Area of interest in SankalpTaru Foundation (You can select multiple)</div>
                            </div>
                            <div class="interest-card bgGreen">
                                <img src="../images/content-writing.svg" class="pad-30-top" alt="Content Writing" />
                                <div class="pad-10 txtWhite">Content Writing</div>
                            </div>

                            <div class="img-2">
                                <img src="../images/field-operations.svg" class="pad-30-top" alt="Field Operations" />
                                <div class="pad-10 txtWhite">Field Operations</div>
                            </div>
                            <div class="img-3">
                                <img src="../images/fundraising.svg" class="pad-30-top" alt="Fundraising" />
                                <div class="pad-10 txtWhite">Fundraising</div>
                            </div>
                            <div class="img-4">
                                <img src="../images/information-tech.svg" class="pad-30-top" alt="Information Technology" />
                                <div class="pad-10 txtWhite">Information Technology</div>
                            </div>
                            <div class="img-5">
                                <img src="../images/fulltime-job.svg" class="pad-30-top"
                                    alt="Full time/Part time Employment" />
                                <div class="pad-10 txtWhite">Full time/Part time Employment</div>
                            </div>
                            <div class="img-6">
                                <img src="../images/media-marketing.svg" class="pad-30-top" alt="Social Media Marketing" />
                                <div class="pad-10 txtWhite">Social Media Marketing</div>
                            </div>
                            <div class="img-7">
                                <img src="../images/forestry.svg" class="pad-30-top" alt="Forestry and Farming Consulting" />
                                <div class="pad-10 txtWhite">Forestry and Farming Consulting</div>
                            </div>
                            <div class="img-8">
                                <img src="../images/fellowship.svg" class="pad-30-top" alt="Fellowship Programme" />
                                <div class="pad-10 txtWhite">Fellowship Programme</div>
                            </div>
                            <div class="img-9">
                                <img src="../images/others.svg" class="pad-30-top" alt="Others" />
                                <div class="pad-10 txtWhite">Others</div>
                            </div>

                            <div class="mb-4">
                                <input type="text" class="form-control" id="otherInterest"
                                    placeholder="If other, please specify" />
                            </div>

                            <div class="para-5">
                                <div>Elaborate on why you wish to join SankalpTaru Foundation- </div>
                                <div>Share you green story the drive behind joining the cause </div>
                            </div>
                            <div class="mb-5 pad-10-top">
                                <input type="text" class="form-control" id="GreenStoryTextBox"
                                    placeholder="Enter Your Answer" />
                            </div>
                            <div class="para-6 form-check form-switch pad-30-top">
                                <label class="form-check-label" for="subscribeWhatsapp">Subscribe for WhatsApp updates of
                                    plantation events. (<small>To avail this, WhatsApp number is mandatory</small>)</label>
                                <input class="form-check-input" type="checkbox" id="subscribeWhatsapp" checked />
                            </div>
                            <div class="para-7 form-check form-switch pad-30-top">
                                <label class="form-check-label" for="subscribe">Subscribe for our Monthly
                                    Newsletters</label>
                                <input class="form-check-input" type="checkbox" id="subscribe" checked />
                            </div>
                        </div>

                        <div class="para-8">
                            <label class="radio-inline" For="Permit"><input type="radio" id="Yes" name="EnvOrg"
                                    value="Yes" />I permit SankalpTaru to contact me for any future volunteering or relief
                                activities</label>
                        </div>
                        <button class="submit btn btn-default">
                            Submit
                        </button>

                    </div>
                </form>

            </div>            
        </div>
    );
}

export default FormData;
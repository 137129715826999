import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Collapse } from "react-bootstrap";
import { Account, AccountContext } from "../Account";
import { PostApiRequest, GetApiRequest, PutApiRequest } from "../ApiRequest";
import {Audio} from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { clearLoginDataSuccessfull, clearPermissionDataSuccessfull, isDashboardLoaded, getLoginDataSuccessfull, getPermissionDataSuccessfull } from '../../redux/users/usersActions'

function UserInfoModal() {
  const dispatch = useDispatch();
  const { getUser, logout } = useContext(AccountContext);
  const [isShow, setIsShow] = React.useState(false);
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState([]);
  const [updatedUserData, setUpdatedUserData] = useState("");
  const [isHide, setIsHide] = useState(false);
  const isPermitted = useSelector(state => state?.users?.isPermission);
  const url = process.env.REACT_APP_API_BASE_URL + "new/action";
  const finalUrl = process.env.REACT_APP_API_BASE_URL + "users";
  const permissionValues = ["View", "Edit"];

  const initModal = () => {
    setUpdatedUserData("");
    setUserId("");
    setUserData([]);
    setIsShow(false);
  };

  const payload = {
    actiontype: "Logout",
    actiondesc: "Logout Attempt",
    actiondoneby: user.name,
    actiondate: new Date().toISOString(),
  };

  const signout = async () => {
    setIsHide(true)
    await PostApiRequest(url, payload);
    dispatch(getLoginDataSuccessfull({}));
    dispatch(getPermissionDataSuccessfull(false));
    dispatch(isDashboardLoaded(false));
    await logout();
  };

  useEffect(() => {
    setUpdatedUserData("");
    setUserId("");
    setUserData([]);
    const fetchUser = async () => {
      try {
        const user = await getUser();
        setUser(user);
      } catch (err) {
        console.error(err);
      }
    };
    fetchUser();
  }, []);
  
  useEffect(() => {
    if (updatedUserData) {
      GetApiRequest(`${process.env.REACT_APP_API_BASE_URL}user/${updatedUserData}`).then((res) =>{
        if(res?.data?.length > 0){
            console.log(res);
            const response = {
              ...res?.data[0],
              isExapanded: false,
              permission: res?.data[0]?.permission ?? 'View'
            }
              setUserData([...userData, response]);
              setUserId("");
          }
      });
    }
  }, [updatedUserData]);

  const handleChange = (e) => {
    e.preventDefault();
    setIsShow(true);
    setOpen(false);
  };

  const handleInputChange = (e) => {
    setUserId(e.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setUpdatedUserData(userId);
    }
  }

  const handleDropDownChange = (event) =>{
    const fileredData = userData?.map(user => {
      if(event.target.name === user.email){
        user.isExapanded = !user.isExapanded;
      } else {
        user.isExapanded = false;
      }
      return user;
    })
   setUserData(fileredData);
  }

  const handleSelectedItem = (selectedPermission, email) =>{
    const fileredData = userData?.map(user => {
        return{
          ...user,
          permission: email === user.email ? selectedPermission : user.permission,
          isExapanded: false
        }
    })
    setUserData(fileredData);
  }
 
  const handleSubmit = async () => {
    const filteredData = userData && userData?.length > 0 ? userData?.map((userdata)=>{
      return {
        email: userdata?.email,
        permission: userdata?.permission,
        updatedby: user?.name,
        updateddate: new Date().toISOString()
      }
    }) : [];
    await PutApiRequest(finalUrl, filteredData)
    initModal();
  }

  return (
    <Account>
      {user ? (
        <div className="loginsection">
          <span>
            <a className="btn bgGreen txtWhite" onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open} style={{ fontSize: "12px", boxShadow: "none", padding: "0px 4px 0px 0px" }}>
              {user?.name}
            </a>
            <i class="bi bi-person-circle" style={{ color: "white", fontSize: "1.2rem" }}></i>
          </span>
          <Collapse in={open}>
            <ul id="example-collapse-text">
             { isPermitted && <li style={{ borderBottom: "1px solid white" }}>
                <a onClick={handleChange}>
                  <i class="bi bi-person-fill-add"></i>
                  Add or give access
                </a>
              </li>}
              <li className="logoutSection">
                <a onClick={signout} style={{ cursor: "pointer", marginRight: "5px" }}>
                  <i class="bi bi-box-arrow-right"></i>
                  {isHide ? "Logging out" : "Log out"}
                </a>
                {isHide && (
                  <div className="loader">
                    <Audio
                      height="18"
                      width="16"
                      color="#4fa94d"
                      ariaLabel="audio-loading"
                      wrapperStyle={{}}
                      wrapperClass="wrapper-class"
                      visible={true}
                    />
                  </div>
                )}
              </li>
            </ul>
          </Collapse>
        </div>
      ) : null}

      <Modal show={isShow}>
        <Modal.Header className="modalHeader" onClick={initModal}>
          <div className="addMoresection">
            <span>
              <a className="btn bgGreen txtWhite" style={{ fontSize: "12px", boxShadow: "none", padding: "0px 4px 0px 0px" }}>
                {user.name}
              </a>
              <i class="bi bi-person-circle" style={{ color: "white", fontSize: "1.2rem" }}></i>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <input type="text" placeholder="Add more" value={userId} onChange={handleInputChange} onKeyDown={handleKeyDown} />
          <div className="section">
            {userData?.length > 0 ? userData?.map((res, i) => (
              <div className="dropdown-wrapper" key={res.email}>
                <input type="text" value={res.email} />
                <div className="dropdown-section" style={res.isExapanded ? {zIndex: 10} : {zIndex: 0}}>
                  <span >
                    <a className="btn bgGreen txtWhite" name={res.email} value={res.email} onClick={handleDropDownChange} aria-controls="example-collapse-text" aria-expanded={open} style={{ fontSize: "12px", boxShadow: "none", padding: "5px 0px 5px 15px" }}>
                      {res.permission} 
                     { res.isExapanded ? <i class="bi bi-chevron-up"></i> : <i class="bi bi-chevron-down"></i> }
                    </a>
                    
                  </span>
                  <Collapse key={i} in={res.isExapanded}>
                    <ul id="example-collapse-text">
                      {permissionValues.map((item) => (
                        <li
                          key={res.email}
                          as="button"
                          onClick={() => handleSelectedItem(item, res.email)}
                          style={{cursor:'pointer'}}
                          name={res.email}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                </div>
              </div>

            )) : []}
          </div>
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <Button disabled={!userData?.length} className="btn statusactive" onClick={handleSubmit}>
            Share
          </Button>
        </Modal.Footer>
      </Modal>
    </Account>
  );
}
export default UserInfoModal;

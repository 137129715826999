import React from 'react';
import './Banner.css';
// import '../../../public/Images/banner.png';
// import "../../../public/Images"
import BannerImage from '../../Images/banner.png'
import Voluteer1 from '../../Images/volunteer1.png'
import Voluteer2 from '../../Images/volunteer2.png'
import Voluteer3 from '../../Images/volunteer3.png'
import Voluteer4 from '../../Images/volunteer4.png'

function Banner(props) {
    return (
        // <div className="banner">
        //     <div className="banner-area row noPad noMargin">
        //         <div className="row noPad noMargin">
        //             <div className="banner-texts col-lg-8 col-md-8 col-sm-12">
        //                 <h1>Greenery is calling you. Come, let’s together help it escalate!</h1>
        //                 <div className="content-area">
        //                     <div className="wrapper-1">
        //                         At SankalpTaru, we go beyond a typical volunteering program and help you learn, explore,
        //                         experience and have great fun. And as they say, strong bonds do not seek a correct timing or
        //                         place, you could anytime become our next green asset.
        //                         Volunteer with us to delight environment all across the globe!
        //                     </div>
        //                     <div className="button-v">
        //                         <button className="my-button">volunteer with us</button>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="banner-images col-lg-4 col-md-4 col-sm-4 d-sm-block">
        //                 <div className="img-bg">
        //                     <img src="../images/volunteer1.png" alt="..." />
        //                 </div>
        //                 <div className="img-bg-1">
        //                     <img src="../images/volunteer2.png" alt="..." />
        //                 </div>
        //                 <div className="img-bg-2">
        //                     <img src="../images/volunteer3.png" alt="..." />
        //                 </div>
        //                 <div className="img-bg-3">
        //                     <img src="../images/volunteer4.png" alt="..." />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>            
        // </div>
        <div className="card banner-card">
            
            {/* <img className="card-image" src="../../../public/Images/banner.png" alt="banner-image" /> */}
            <img className="card-image" src={BannerImage} alt="banner-image" />

            <div className="container-fluid container-xl banner-content-container">
                <div className="row banner-row">
                <div className="col-12 col-xl-8 banner-container">
                    <h2 className="card-title banner-header">Greenery is calling you. Come, let’s together help it escalate!</h2>
                    <p className="card-text banner-text">
                        At SankalpTaru, we go beyond a typical volunteering program and help you learn, explore,experience and have great fun. And as they say, strong bonds do not seek a correct timing or place, you could anytime become our next green asset. Volunteer with us to delight environment all across the globe!
                    </p>
                    <button className="banner-button">Volunteer with us</button>
                    
                </div>
                <div className="col-12 col-xl-4 banner-images-container d-sm-none d-xl-block">
                     <div className="banner-images">
                         <div className="img-bg">
                             <img className='circled-image' src={Voluteer1} alt="..." />
                         </div>
                         <div className="img-bg-1">
                             <img className='circled-image' src={Voluteer2} alt="..." />
                         </div>
                         <div className="img-bg-2">
                             <img className='circled-image' src={Voluteer2} alt="..." />
                         </div>
                         <div className="img-bg-3">
                             <img className='circled-image' src={Voluteer4} alt="..." />
                         </div>
                     </div>                    

                </div>
                </div>

            </div> 

        </div>
    );
}

export default Banner;





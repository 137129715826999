import { applyMiddleware, compose } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import { rootReducer } from './rootReducer';

const persistConfig = {
  key: 'data',
  storage,
};
const sagaMiddleware = createSagaMiddleware()
const allStoreEnhancers = compose
  (
    applyMiddleware(sagaMiddleware),
  );

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the redux store
export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
          serializableCheck: {
              ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,allStoreEnhancers],
          },
      })
});
import React, { useEffect, useState } from "react";
import "./DashboradTable.css";
import BootstrapTable from "react-bootstrap-table-next";
import $ from "jquery";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter, Comparator, selectFilter } from "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { PostApiRequest, PutApiRequest } from "../ApiRequest";
import paginationFactory from "react-bootstrap-table2-paginator";
import contentWriting from "../../Images/content-writing.png";
import fieldOperations from "../../Images/field-operations.png";
import fundRaising from "../../Images/fund-raising.png";
import informationTechnology from "../../Images/information-technology.png";
import fulltime from "../../Images/employment.png";
import socialMedia from "../../Images/marketing.png";
import forestyConsulting from "../../Images/forestry-consulting.png";
import fellowship from "../../Images/fellowship.png";
import others from "../../Images/others.png";
import { useSelector } from "react-redux";

export default function DashboardTable() {
  const [permitted, setPermitted] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  const [volunteersData, setVolunteersData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [filteredData, setFilteredData] = useState([
    { name: "All", color: "btn statusall" },
    { name: "Active", color: "btn btn-secondary" },
    { name: "Dormant", color: "btn btn-secondary" },
    { name: "InActive", color: "btn btn-secondary" },
  ]);
  const [sorted, setSorted] = useState({
    field: "",
    order: "",
  });
  const isPermitted = useSelector((state) => state?.users?.isPermission);

  let columns;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const url = process.env.REACT_APP_API_BASE_URL + "new/action";

  const payload = {
    actiontype: "ExportCSV",
    actiondesc: "exporting data into csv",
    actiondoneby: "Yugandhar Macherla",
    actiondate: new Date().toISOString(),
  };

  // Pagination options with currentPage and sizePerPage from state
  const paginationOptions = {
    page: currentPage, // Use the current page from state
    sizePerPage: sizePerPage, // Use the size per page from state
    totalSize: volunteersData.length, // Adjust based on actual data size
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page);
      setSizePerPage(sizePerPage);
    },
    // Add any other pagination options here
  };
  const dropdownListForStatus = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Dormant",
      label: "Dormant",
    },
    {
      value: "InActive",
      label: "InActive",
    },
  ];

  columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "fullname",
      text: "Volunteer Name",
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "phonenumber",
      text: "Phone Number",
      hidden: true,
    },
    {
      dataField: "emailid",
      text: "E-mail",
      hidden: true,
    },
    {
      dataField: "gender",
      text: "Gender",
      hidden: true,
    },
    {
      dataField: "bloodgroup",
      text: "Blood Group",
      hidden: true,
    },
    {
      dataField: "address1",
      text: "Address1",
      hidden: true,
    },
    {
      dataField: "address2",
      text: "Address2",
      hidden: true,
    },
    {
      dataField: "pincode",
      text: "pincode",
      hidden: true,
    },
    {
      dataField: "city",
      text: "city",
      hidden: true,
    },
    {
      dataField: "state",
      text: "state",
      hidden: true,
    },
    {
      dataField: "areasofinterest",
      text: "Volunteering Area Of interest",
      formatter: (cellContent, row) => cellAOIFormatter(cellContent, row),
      filterValue: (cell, row) => cellAOIFormatFilter(cell, row),
      editable: false,
    },
    {
      dataField: "updateddate",
      text: "Applied On",
      sort: true,
      editable: false,
      formatter: (cell, row) => {
        // Custom date formatting, e.g., converting ISO date to "MM/DD/YYYY" format
        const date = new Date(cell);
        const formattedDate = date.toLocaleString();
        return formattedDate;
      },
    },
    {
      dataField: "status",
      text: "Status",
      editable: permitted,
      formatter: (cell, row) => statusDetail(cell, row),
      editor: {
        type: Type.SELECT,
        options: dropdownListForStatus,
      },
    },
    {
      dataField: "othersifany",
      text: "othersifany",
      hidden: true,
    },
  ];

  const handleAddSaveOtherInterests = (e, data, rows, rowIndex) => {
    if (rows.buttonText === "Edit & Add") {
      const updatedData = volunteersData.map((row) => (row.id === rows.id ? { ...row, enabled: true } : row));
      setVolunteersData(updatedData);
    }
    if (rows.buttonText === "Save") {
      const updatedData = volunteersData.map((row) => (row.id === rows.id ? { ...row, enabled: false } : row));
      const url = process.env.REACT_APP_API_BASE_URL + rows.id;
      setVolunteersData(updatedData);
      const payload = {
        status: rows.status,
        othersifany: rows.othersifany,
        updateddate: new Date().toISOString().toLocaleString(),
      };
      PutApiRequest(url, payload).then((res) => {
        if (res.message) {
          const updatedData = volunteersData.map((row) => (row.id === rows.id ? { ...row, enabled: false, buttonText: "Edit & Add" } : row));
          setVolunteersData(updatedData);
        }
      });
    }
  };

  const handleOtherInterests = (e, data, rows, rowIndex) => {
    const updatedData = volunteersData.map((row) => (row.id === rows.id ? { ...row, othersifany: e.target.value, buttonText: "Save" } : row));
    setVolunteersData(updatedData);
  };

  // Implement startWith instead of contain
  function customMatchFunc({ searchText, value, column, row }) {
    if (typeof value !== "undefined") {
      return value.startsWith(searchText);
    }
    return false;
  }

  // Implement format colum and search value
  function cellAOIFormatter(cellContent, row) {
    let aof = cellContent.split(/[.:;?!~,`"&|()<>{}\[\]\r\n/\\]+/).join(", ");
    return <div>{aof}</div>;
  }

  function cellAOIFormatFilter(cell, row) {
    return row.areasofinterest;
  }

  function statusDetail(cellContent, row) {
    let statusVal = "";
    if (cellContent === "Active") {
      statusVal = "btn statusactive";
    } else if (cellContent === "InActive") {
      statusVal = "btn statusinactive";
    } else if (cellContent === "Dormant") {
      statusVal = "btn statusdormant";
    }
    return (
      <button className={statusVal} disabled={!permitted}>
        {dropdownListForStatus.find((x) => x?.value === cellContent)?.label}
      </button>
    );
  }

  const handleDropdownChange = (e) => {
    textFilter(e.target.value);
  };

  const handleSortByDate = (e) => {
    if (e.target.value === "null") {
      return;
    } else {
      setSorted({ ...sorted, field: "updateddate", order: e.target.value });
    }
  };

  const handleExport = () => {
    PostApiRequest(url, payload);
  };

  const handleActive = (e) => {
    let className = "";
    if (e.target.value === "All") {
      className = "btn statusactive";
    }
    if (e.target.value === "Active") {
      className = "btn statusactive";
    }
    if (e.target.value === "InActive") {
      className = "btn statusinactive";
    }
    if (e.target.value === "Dormant") {
      className = "btn statusdormant";
    }
    const listOfFilteredData = filteredData.map((row) => (row.name === e.target.value ? { ...row, isactive: true, color: className } : { ...row, isactive: false, color: "btn btn-secondary" }));
    setFilteredData(listOfFilteredData);
    if (e.target.value === "All") {
      setVolunteersData(originalData);
    } else {
      const updatedData = originalData.filter((row) => row.status === e.target.value);
      setVolunteersData(updatedData);
    }
  };

  useEffect(() => {
    setPermitted(isPermitted);
  }, [permitted]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL)
      .then((response) => response.json())
      .then((data) => {
        const updatedData =
          data?.data &&
          data?.data?.map((item) => {
            return {
              ...item,
              enabled: false,
              buttonText: "Edit & Add",
            };
          });
        setVolunteersData(updatedData);
        setOriginalData(updatedData);
      });
  }, []);

  const onAfterSaveCell = (oldValue, newValue, rows, column) => {
    // const { id, status } = row;
    // const updatedData = volunteersData.map((row) => (row.id === id ? { ...row, status: newValue } : row));
    // setVolunteersData(updatedData);
    // setTableKey((prevKey) => prevKey + 1);

    setTableKey((prevKey) => prevKey + 1);
    const { id } = rows;
    const url = process.env.REACT_APP_API_BASE_URL + id;
    const payload = {
      status: newValue,
      updateddate: new Date().toISOString().toLocaleString(),
    };
    PutApiRequest(url, payload).then((res) => {
      if (res.message) {
        const updatedData = volunteersData.map((row) => (row.id === id ? { ...row, status: newValue } : row));
        setVolunteersData(updatedData);
      }
    });
  };

  const expandRow = {
    renderer: (row, rowIndex, cellContent) =>
      permitted && (
        <div className="form-inline rowwrapdetails txtLeft">
          {Object.entries(row).map(([key, value]) => {
            if (key === "emailid" || key === "phonenumber" || key === "bloodgroup" || key === "pincode" || key === "state" || key === "city" || key === "address1") {
              return (
                <div className="form-group">
                  <label>{key?.toUpperCase()}</label>
                  <input type="text" value={value} className="form-control disabled" />
                </div>
              );
            }
            if (key === "areasofinterest") {
              let aof = value.split(/[.:;?!~,`"&|()<>{}\[\]\r\n/\\]+/);
              let imgsrcAOF = null;
              let aofToLowerCase = null;
              return (
                <>
                  <div className="col-5">
                    <div className="form-group">
                      <label>Areas of interest</label>
                      <ul className="aofwrap">
                        {aof.map((element, index) => {
                          aofToLowerCase = element.toLowerCase();
                          if (aofToLowerCase === "contentwriting") {
                            imgsrcAOF = contentWriting;
                          }
                          else if (aofToLowerCase === "fieldoperations") {
                            imgsrcAOF = fieldOperations;
                          } else if (aofToLowerCase === "fundraising") {
                            imgsrcAOF = fundRaising;
                          } else if (aofToLowerCase === "informationtechnology") {
                            imgsrcAOF = informationTechnology;
                          }
                          else if (aofToLowerCase === "employment") {
                            imgsrcAOF = fulltime;
                          }
                           else if (aofToLowerCase === "socialmediamarketing") {
                            imgsrcAOF = socialMedia;
                          } else if (aofToLowerCase === "forestryconsulting") {
                            imgsrcAOF = forestyConsulting;
                          } else if (aofToLowerCase === "fellowshipprogram") {
                            imgsrcAOF = fellowship;
                          }
                           else {
                            imgsrcAOF = others;
                          }
                          return (
                            <li key={index}>
                              <img className="image-select" src={imgsrcAOF} alt={imgsrcAOF} />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </>
              );
            }
            if (key === "othersifany") {
              return (
                <div className="form-group" style={{ position: "relative" }}>
                  <textarea id="commentText" rows="5" value={value} className={row.enabled ? "" : "disabled"} cols="50" onChange={(e) => handleOtherInterests(e, value, row, rowIndex)} />
                  <button className="btn btn-default commentEditBtn" style={{ padding: "6px 12px" }} onClick={(e) => handleAddSaveOtherInterests(e, value, row, rowIndex)}>
                    {row.buttonText}
                  </button>
                </div>
                
              );
            }
          })}
        </div>
      ),
    showExpandColumn: permitted,
    expandByColumnOnly: permitted,
    expandColumnPosition: "right",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <b>-</b>;
      }
      return <b>+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <i className="bi bi-chevron-up"></i>;
      }
      return <i className="bi bi-chevron-down"></i>;
    },
  };
  
  const csvFileName = 'volunteers.csv'; // Specify the custom sheet name here

  return (
    <div className="row dashboard-main">
      <div className="col-12 pad-t-35">
        <ToolkitProvider exportCSV={{fileName:csvFileName}} keyField="id" data={volunteersData} columns={columns} search={{ customMatchFunc }}>
          {(props) => (
            <>
              <div className="row pad-20-bottom">
                <div className="col-3">
                  <div className="inputiconwrap">
                    <i className="bi bi-filter"></i>
                    <select className="form-select" id="dropdown" onChange={handleDropdownChange}>
                      <option value="null">Filter</option>
                      <option value="Name">City</option>
                      <option value="AppliedOn">State</option>
                      <option value="BloodGroup">Blood Group </option>
                      <option value="AgeGroup">Age Group </option>
                      <option value="VolunteeringArea">Volunteering Area </option>
                      <option value="PinCode">Pin Code</option>
                    </select>
                  </div>
                </div>
                <div className="col-3">
                  <div className="inputiconwrap">
                    <i className="bi bi-search"></i>
                    <SearchBar {...props.searchProps} />
                  </div>
                </div>
                <div className="col-3">
                  <div className="inputiconwrap">
                    <i className="bi bi-filter-left"></i>
                    <select className="form-select" id="dropdown" onChange={handleSortByDate}>
                      <option value="null">Sort by Date</option>
                      <option value="desc">Sort Newest to Oldest</option>
                      <option value="asc">Sort Oldest to Newest</option>
                    </select>
                  </div>
                </div>
                {permitted && (
                  <div className="col-3 pull-right" style={{ textAlign: "right" }} onClick={handleExport}>
                    <div className="inputiconwrap">
                      <i className="bi bi-download"></i>
                      <ExportCSVButton {...props.csvProps}>Export Data</ExportCSVButton>
                    </div>
                  </div>
                )}
              </div>
              <div className="row pad-20-bottom txtLeft">
                <div className="col-12">
                  {filteredData.map((list) => {
                    return (
                      <button className={`margin-10 ${list.color}`} value={list.name} onClick={handleActive}>
                        {list.name}
                      </button>
                    );
                  })}
                </div>
              </div>
              <BootstrapTable
                key={tableKey}
                {...props.baseProps}
                sort={{
                  dataField: sorted.field,
                  order: sorted.order,
                }}
                expandRow={expandRow}
                filter={filterFactory()}
                cellEdit={cellEditFactory({ mode: "click", blurToSave: true, afterSaveCell: onAfterSaveCell })}
                //pagination={volunteersData.length > 10 ? paginationFactory({ sizePerPage: 10 }) : null}
                pagination={paginationFactory(paginationOptions)}
                afterSaveCell={onAfterSaveCell}
                bordered={false}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

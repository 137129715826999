import React from "react";
import { useSelector } from "react-redux";
import LeftNav from "../LeftNav";
import { Outlet } from "react-router-dom";
import UserInfoModal from "./UserInfoModal";
import {ThreeCircles} from "react-loader-spinner";

function DashboardPage() {
  const isLoaded = useSelector((state) => state?.users?.isDashboardLoaded);
  
  return (
    <div className="col-lg-12 row main-container">
      <div className="col-lg-2 pad-0-right main-container-leftnav">
        <LeftNav />
      </div>
      <div className="col-lg-10 pad-0-left pad-0-right main-wrapper">
        <>
          {isLoaded ? (
            <>
              <div className="wrapper-container">
                <UserInfoModal />
              </div>
              <div className="pad-t-50">
                <Outlet></Outlet>
              </div>
            </>
          ) : (
               <ThreeCircles
                    height="100"
                    width="100"
                    color="#4fa94d"
                    wrapperStyle={{justifyContent: "center", margin: "10vh 0"}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                />
          )}
        </>
      </div>
    </div>
  );
}

export default DashboardPage;

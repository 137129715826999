import React, {useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Account } from './Components/Account';
import Login from './Components/Login';
import Register from './Components/Register';
import Status from './Components/Status';
import Navbar from './Components/Navbar';
import VolunteerPage from './Components/Volunteer/VolunteerPage';
import DashboardPage from './Components/Dashboard/DashboardPage';
import DashboardTable from './Components/Dashboard/DashboardTable';
import PrivateRoute from './Components/PrivateRoute';
import RecentInteractions from './Components/Dashboard/RecentInteractions';
import { Provider } from 'react-redux'
import store from './redux/initializeRedux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
//...
let persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
         <PersistGate persistor={persistor}>
    <Account>
     {/* <Navbar /> */}
     {/* <Status /> */ }
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<VolunteerPage/>} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path='/volunteerForm' element={<VolunteerPage />} />
          <Route exact element={<PrivateRoute/>}>
            <Route exact path='/dashboard' element={<DashboardPage/>}>
                <Route exact path='/dashboard' element={<DashboardTable />} />
                <Route exact path='/dashboard/RecentInteractions' element={<RecentInteractions/>} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Account>
    </PersistGate>
    </Provider>
  );
}

export default App;
import React, { useState, useEffect } from 'react'
import './DashboradTable.css'; 
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter';
import paginationFactory from "react-bootstrap-table2-paginator";


export default function RecentInteractions(props) {
  const [selectValue, setSelectValue] = useState("");
  const [actions, setActions] = useState([]);
  const [sorted, setSorted] = useState({
      field: "",
       order: ""
    });

  
  const handleSortByDate = (e) => {
    console.log(e.target.value);
   
    if(e.target.value === 'null')
    {
     return;
    }else{
      setSorted({...sorted, field: 'updateddate', order: 'e.target.value'})
      // this.setState({
      //   field: 'updateddate',
      //   order: e.target.value
      // });
    }   
  }
  console.log ("recent actions rendered");
  useEffect(()=> {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/recentactions`)
      .then(response => response.json())
      .then(data => setActions(data.data));
  }, []);

    const columns = [
      {
        dataField: "actionid",
        text: "id",
       // hidden: true
      },{
        dataField: "actiontype",
        text: "Action Type",
       // filter: textFilter()
      },{
        dataField: "actiondesc",
        text: "Action Description",
       // hidden: true
      },{
        dataField: "actiondoneby",
        text: "Actioned By",
     //   hidden: true
      },{
        dataField: "actiondate",
        text: "Date",
       // hidden: true
      }

    
    ];
    return (
      // <h1>rajendfa</h1>
      <div className="row dashboard-main">
        {/* <div className='col-12 pad-30-bottom pullRight txtRight'> */}
        {/* <UserInfoModal /> */}
        {/* </div> */}
        <div className='col-12'>
       <ToolkitProvider
            keyField="id"
            data={ actions }
            columns={ columns }
            filter={ filterFactory() }
            cellEdit={ {
              mode: "click",
              blurToSave: true,
             } } 
            sort={ {
              dataField: sorted.field,
              order: sorted.field.order
            } }
            
          >
          {
          props => (
              <div>
                <BootstrapTable
                    { ...props.baseProps }
                    data={ actions }
                    sort={ {
                      dataField: sorted.field,
                      order: sorted.order
                    } }
                    pagination={actions?.length > 10 ? paginationFactory({sizePerPage: 10}) : null}
                    bordered={ false }
                  />
              </div>
              )
            }
          </ToolkitProvider>
          </div>
      </div>
    );
}

import React, {createRef} from "react";
import {useState} from "react";
import {Formik, Field, Form, ErrorMessage} from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import ErrorComponent from "./ErrorComponent";
import "./VolunteerForm.css";
import {Modal, Button} from "react-bootstrap";
import contentWriting from "../../Images/content-writing.png";

import fieldOperations from "../../Images/field-operations.png";
import fundRaising from "../../Images/fund-raising.png";
import informationTechnology from "../../Images/information-technology.png";
import fulltime from "../../Images/fulltime-job.png";
import socialMedia from "../../Images/marketing.png";
import forestyConsulting from "../../Images/forestry-consulting.png";
import fellowship from "../../Images/fellowship.png";
import others from "../../Images/others.png";

const initialState = {
  fullname: "",
  emailid: "",
  phonenumber: "",
  dob: "",
  gender: "male",
  bloodgroup: "",
  pincode: "",
  address1: "",
  state: "",
  city: "",
  associatedwithenvironmentorg: "no",
  describeassociation: "",
  areasofinterest: [],
  othersifany: "",
  joiningcause: "",
  whatsappupdates: false,
  subscribefornewsletters: false,
  consent: false,
  status: "",
  createddate: "",
  updateddate: "",
  recaptcha: "",
};
const phoneRegex = /^\d{10}$/;
const validationSchema = Yup.object().shape({
  fullname: Yup.string().required("Name is required"),
  emailid: Yup.string().email("Invalid email").required("Email is Required"),
  // phonenumber: Yup.number().required().min(1000000000, 'Must be exactly 10 digits').max(9999999999, 'Must be exactly 10 digits'),
  phonenumber: Yup.string().matches(phoneRegex, "Please Enter a 10 digit mobile Number"),
  dob: Yup.date().required("Date of Birth is required"),
  recaptcha: Yup.string().required("reCaptcha is required"),
});

// form POST method implementation:
async function postFormData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response; // parses JSON response into native JavaScript objects
}

const statesList = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Orissa",
  "Pondicherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttaranchal",
  "Uttar Pradesh",
  "West Bengal",
];
const citiesList = ["delhi", "mumbai", "chennai", "hyderabad", "kolkata", "pune", "noida", "kochi", "bengaluru", "Noida"];

const statesOptions = statesList.map((state, key) => {
  <option value={state} key={key}>
    {" "}
    {state}
  </option>;
});

console.log("statesoptions", ...statesOptions);

function VolunteerForm(props) {
  const [formSuccess, setFormSuccess] = useState(false);

  const [formError, setFormError] = useState(false);
  const [volunteerExists, setVolunteerExists] = useState(false);
  const recaptchaRef = React.createRef();
  const submitHandler = async (values, actions) => {
    actions.setSubmitting(false);
    recaptchaRef.current.reset(); 
    if (values.associatedwithenvironmentorg === "yes") values.associatedwithenvironmentorg = 1;
    else {
      values.associatedwithenvironmentorg = 0;
    }
    if (values.othersifany === undefined) values.othersifany = "";
    values.status = "Active";
    values.address2 = "";
    values.createddate = new Date().toISOString().toLocaleString();
    values.updateddate = new Date().toISOString().toLocaleString();

    values.whatsappupdates = values.whatsappupdates ? 1 : 0;
    values.subscribefornewsletters = values.subscribefornewsletters ? 1 : 0;
    values.consent = values.consent ? 1 : 0;

    console.log(JSON.stringify(values));
    var url = process.env.REACT_APP_API_BASE_URL;
    var response = await postFormData(url + "new", values);
    console.log("response status:" + response.status);
    if (response.status === 200) {
      actions.resetForm();
      setVolunteerExists(true);
      recaptchaRef.current.reset();
    } else if (response.status === 201) {
      actions.resetForm();
      setFormSuccess(true);
      recaptchaRef.current.reset();
    } else {
      actions.resetForm();
      recaptchaRef.current.reset();
      setFormError(true);
    }
  };

  return (
    <Formik
      //enableReinitialize
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
      //validateOnMount={false}
      //validateOnBlur={true}
      //validateOnChange={false}
      >
      {(formik) => {
        console.log("ntr99999999", formik)
        const othersFieldHandler = () => {
          return !formik?.values.areasofinterest?.includes("Others");
        };
        return (
          <Form>
            <div className="container form-container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 field-container">
                  <label htmlFor="fullname">Name*</label>
                  <Field type="text" name="fullname" id="fullname" placeholder="Enter Name" />
                  <ErrorMessage name="fullname" component={ErrorComponent} />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 field-container">
                  <label htmlFor="emailid">Email ID*</label>
                  <Field type="email" name="emailid" id="emailid" placeholder="Enter Email Id" />
                  <ErrorMessage name="emailid" component={ErrorComponent} />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 field-container">
                  <label htmlFor="phonenumber">Phone*</label>
                  <Field type="text" name="phonenumber" id="phonenumber" placeholder="Enter Phone" />
                  <ErrorMessage name="phonenumber" component={ErrorComponent} />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 field-container">
                  <label htmlFor="dob">Date of Birth*</label>
                  <Field name="dob" id="dob">
                    {(props) => {
                      const {field} = props;
                      return (
                        <>
                          <input {...field} type="date" placeholder="Date/Month/Year" />
                        </>
                      );
                    }}
                  </Field>
                  <ErrorMessage name="dob" component={ErrorComponent} />
                </div>
                {/* update this to dropdown */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 field-container">
                  <label htmlFor="gender">Select Gender</label>
                  <Field name="gender" id="gender" placeholder="Select Gender" as="select">
                    <option value="male" selected>
                      Male
                    </option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Field>
                  <ErrorMessage name="gender" component={ErrorComponent} />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 field-container">
                  <label htmlFor="bloodgroup">Your Blood Group</label>
                  <Field type="text" name="bloodgroup" id="bloodgroup" placeholder="Your Blood Group" />
                  <ErrorMessage name="bloodgroup" component={ErrorComponent} />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 field-container">
                  <label htmlFor="pincode">Pin Code</label>
                  <Field type="text" name="pincode" id="pincode" placeholder="Enter Pin Code" />
                  <ErrorMessage name="pincode" component={ErrorComponent} />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 field-container">
                  <label htmlFor="address1">Address</label>
                  <Field type="text" name="address1" id="address1" placeholder="Enter Address" />
                  <ErrorMessage name="address1" component={ErrorComponent} />
                </div>
                {/* update this to dropdown */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 field-container">
                  <label htmlFor="state">State</label>
                  <Field as="select" name="state" id="state" placeholder="Select State">
                    {statesList.map((state, index) => {
                      return (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage name="state" component={ErrorComponent} />
                </div>
                {/* update this to dropdown */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 field-container">
                  <label htmlFor="city">City</label>
                  <Field type="text" name="city" id="city" placeholder="Select City" />
                  <ErrorMessage name="city" component={ErrorComponent} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 field-container">
                  <div className="row">
                    <div className="col-8 col-sm-10 association-label" htmlFor="associatedwithenvironmentorg">
                      Have you ever been associated with an Environmental Organisation?
                    </div>
                    <div className="col-4 col-sm-2" role="group" id="associatedwithenvironmentorg">
                      <div className="row">
                        <div className="radio-container col-6 col-sm-6">
                          <Field name="associatedwithenvironmentorg" id="yes" value="yes" type="radio" className="green-radio"></Field>
                          <label htmlFor="yes">Yes</label>
                        </div>
                        <div className="radio-container radio-container col-6 col-sm-6">
                          <Field name="associatedwithenvironmentorg" id="no" value="no" type="radio" className="green-radio"></Field>
                          <label htmlFor="no">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ErrorMessage name="associatedwithenvironmentorg" component={ErrorComponent} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 field-container">
                  <Field
                    name="describeassociation"
                    as="textarea"
                    id="describeassociation"
                    placeholder="If yes, please specify"
                    disabled={formik.values.associatedwithenvironmentorg != "yes"}></Field>
                  <ErrorMessage name="describeassociation" component={ErrorComponent} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 field-container interest-container">
                  <label htmlFor="areasofinterest">Area of interest in SankalpTaru Foundation (You can select multiple)</label>
                  <Field name="areasofinterest" id="areasofinterest">
                    {(props) => {
                      const {field} = props;

                      return (
                        <>
                          <Field
                            type="checkbox"
                            {...field}
                            className="image-checkbox"
                            name="areasofinterest"
                            value="ContentWriting"
                            id="contentWriting"
                          />
                          <label className="image-label content-writing-label" htmlFor="contentWriting">
                            <img className="image-select" src={contentWriting} alt="contentWriting" />
                          </label>

                          <Field
                            type="checkbox"
                            {...field}
                            className="image-checkbox"
                            name="areasofinterest"
                            value="FieldOperations"
                            id="fieldoperations"
                          />
                          <label className="image-label field-operations-label" htmlFor="fieldoperations">
                            <img className="image-select" src={fieldOperations} alt="fieldOperations" />
                          </label>

                          <Field type="checkbox" {...field} className="image-checkbox" name="areasofinterest" value="Fundraising" id="fundraising" />
                          <label className="image-label fund-raising-label" htmlFor="fundraising">
                            <img className="image-select" src={fundRaising} alt="fundRaising" />
                          </label>

                          <Field
                            type="checkbox"
                            {...field}
                            className="image-checkbox"
                            name="areasofinterest"
                            value="InformationTechnology"
                            id="informationTechnology"
                          />
                          <label className="image-label information-technology-label" htmlFor="informationTechnology">
                            <img className="image-select" src={informationTechnology} alt="informationTechnology" />
                          </label>

                          <Field type="checkbox" {...field} className="image-checkbox" name="areasofinterest" value="Employment" id="employment" />
                          <label className="image-label employment-label" htmlFor="employment">
                            <img className="image-select" src={fulltime} alt="fullTime" />
                          </label>

                          <Field
                            type="checkbox"
                            {...field}
                            className="image-checkbox"
                            name="areasofinterest"
                            value="SocialMediaMarketing"
                            id="socialMediaMarketing"
                          />
                          <label className="image-label social-media-marketing-label" htmlFor="socialMediaMarketing">
                            <img className="image-select" src={socialMedia} alt="socialMedia" />
                          </label>

                          <Field
                            type="checkbox"
                            {...field}
                            className="image-checkbox"
                            name="areasofinterest"
                            value="ForestryConsulting"
                            id="forestryConsulting"
                          />
                          <label className="image-label forestry-consulting-label" htmlFor="forestryConsulting">
                            <img className="image-select" src={forestyConsulting} alt="forestConsulting" />
                          </label>

                          <Field
                            type="checkbox"
                            {...field}
                            className="image-checkbox"
                            name="areasofinterest"
                            value="FellowshipProgram"
                            id="fellowshipProgram"
                          />
                          <label className="image-label fellowship-program-label" htmlFor="fellowshipProgram">
                            <img className="image-select" src={fellowship} alt="fellowship" />
                          </label>

                          <Field type="checkbox" {...field} className="image-checkbox" name="areasofinterest" value="Others" id="others" />
                          <label className="image-label others-label" htmlFor="others">
                            <img className="image-select" src={others} alt="others" />
                          </label>
                        </>
                      );
                    }}
                  </Field>
                </div>
                <div className="col-12 col-sm-12 col-md-12 field-container">
                  <Field
                    name="othersifany"
                    as="textarea"
                    id="othersifany"
                    placeholder="If Others, please specify"
                    disabled={othersFieldHandler()}></Field>
                  <ErrorMessage name="othersifany" component={ErrorComponent} />
                </div>
                {/* //joiningcause */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 field-container">
                  <label htmlFor="joiningcause">
                    Elaborate on why you wish to join SankalpTaru Foundation- Share your green story the drive behind joining the cause
                  </label>
                  <Field name="joiningcause" as="textarea" id="joiningcause" placeholder="Enter your answer"></Field>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 field-container">
                  <Field name="whatsappupdates" id="whatsappupdates">
                    {(props) => {
                      const {field} = props;
                      return (
                        <>
                          <div className="row container-fluid p-0">
                            <div className="col col-sm-10 col-md-10">
                              <label>
                                Subscribe for WhatsApp updates of plantation events.
                                <span className="subtext"> (To avail this, WhatsApp number is mandatory) </span>{" "}
                              </label>
                            </div>
                            <div className="col col-sm-2 col-md-2">
                              <label className="switch">
                                <input type="checkbox" {...field} value="no" className="whatsapp-button" />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </Field>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 field-container">
                  <Field name="subscribefornewsletters" id="subscribefornewsletters">
                    {(props) => {
                      const {field} = props;
                      return (
                        <>
                          <div className="row container-fluid p-0">
                            <div className="col col-sm-10 col-md-10">
                              <label>Subscribe for our Monthly Newsletters </label>
                            </div>
                            <div className="col col-sm-2 col-md-2">
                              <label className="switch">
                                <input type="checkbox" {...field} value="no" className="whatsapp-button" />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </Field>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 field-container">
                  <div className="row">
                    <div className="col-2 col-sm-2 col-md-1">
                      <Field type="checkbox" name="consent" id="consent" className="green-checkbox" />
                    </div>
                    <div className="col-10 col-sm-10 col-md-11">
                      <label htmlFor="consent">I permit SankalpTaru to contact me for any future volunteering or relief activities</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 field-container">
                <div className="col-2 col-sm-2 col-md-1">
                  <ReCAPTCHA
                  ref={recaptchaRef}
                    name="recaptcha"
                    sitekey="6LdAqa4ZAAAAAKa38qa6TcU3sW0rBbuoWDTAQUb6"
                    onChange={(value) => {
                        formik.setFieldValue("recaptcha", value);
                        //formik.setFieldTouched("recaptcha", true);
                        //formik.isValid(true)
                    }}
                  />
                  <ErrorMessage name="recaptcha" component={ErrorComponent} />
                </div>
                <div className="col-10 col-sm-10 col-md-11">
                  <button className="banner-button" disabled={!formik.isValid}>
                    submit
                  </button>
                </div>
              </div>
            </div>
            {formSuccess && (
              <Modal show={formSuccess} onHide={() => setFormSuccess(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Thanks for applying to volunteer with us!</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setFormSuccess(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            {volunteerExists && (
              <Modal show={volunteerExists} onHide={() => setVolunteerExists(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Volunteer is already exists!</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setVolunteerExists(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            {formError && (
              <div className="alert alert-warning" role="alert">
                Error happened while submitting!
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default VolunteerForm;

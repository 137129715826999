import React from "react";
import "./LeftNav.css";
import { NavLink, useLocation } from "react-router-dom";

const LeftNav = () => {
  const location = useLocation();
  const dashboardClass = location.pathname === "/dashboard" ? "isactive" : "";
  const RecentInsteractionsClass = location.pathname === "/dashboard/RecentInteractions" ? "isactive" : "";

  return (
    <div id="leftNav">
      <div className="pad-30-bottom">
        <a className="navbar-brand" href="#">
          <img className="logo" src="/Images/sankalptaru-logo.png" alt="logo" />
        </a>
      </div>
      <div className="spacer15"></div>
      <nav>
        <ul>
          <NavLink activeClassName="is-active" to="/dashboard" className={dashboardClass}>
          <i class="bi bi-envelope-open"></i>
            <span style={{marginLeft:10}}>All Applications</span>
          </NavLink>

          <NavLink activeClassName="is-active" to="/dashboard/RecentInteractions" className={RecentInsteractionsClass}>
          <i class="bi bi-chat-square-dots"></i>
            <span style={{marginLeft:10}}>Recent Interactions</span>
          </NavLink>
        </ul>
      </nav>
    </div>
  );
};

export default LeftNav;

export async function PostApiRequest(url, payload){
  let responseData;
  await fetch(url, {
      method: "POST",
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(response => responseData = response)
    .catch(error => {
      console.log(error);
    });
    return responseData
};

export async function GetApiRequest(url, payload){
 let res;
  await fetch(url, {
      method: "GET",
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      }
  })
    .then(response => response.json())
    .then(response => res = response)
    .catch(error => {
      console.log(error);
    });
    console.log(res);
    return res
};

export async function PutApiRequest(url, payload){
  let responseData;
  await fetch(url, {
      method: "PUT",
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(response => responseData = response)
    .catch(error => {
      console.log(error);
    });
    return responseData
};